import I18N from 'next-i18next';
import { localeSubpaths } from './src/locales/languagesData';

const path = require('path');

let siteUrl = process.env.SITE_DOMAIN || process.env.SITE_URL;

siteUrl = siteUrl.replace(/http:\/\/|https:\/\//g,'');

const i18nInstance = new I18N({
  localePath: path.resolve(process.env.GATSBY_LOGGER ? './public/public/static/locales' : './public/static/locales'),
  nsSeparator: false,
  keySeparator: false,
  defaultNS: 'common',
  fallbackLng: 'ru',
  initImmediate: false,
  debug: false,
  otherLanguages: ['ru', 'ae', 'es', 'id', 'hi', 'kr', 'pt', 'zh', 'de', 'it'],
  localeSubpaths,
  detection: {
    lookupCookie: 'next-i18next',
    order: ['path', 'cookie', 'sessionStorage', 'localStorage', 'navigator', 'htmlTag', 'subdomain', 'querystring'],
    lookupFromPathIndex: 0,
    cookieOptions: { path: '/', domain: `.${siteUrl}` },
  },
  react: {
    useSuspense: false
  }
});

export const {
  Trans,
  Link,
  Router,
  i18n,
  initPromise,
  config,
  useTranslation,
  withTranslation,
  appWithTranslation,
  I18nextProvider
} = i18nInstance;
